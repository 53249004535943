body{
    background-image: url("../public/img/back1.jpeg");
    background-size: cover;
    height: 100vh;
}

.Nav_links ul{
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 5vw;
    justify-content: left;
    padding: 2vh;
    padding-inline-start: 10vw;
    background-color: rgb(16, 16, 16,0.4);
    box-shadow: 1px 1px 1px  rgba(75, 74, 74, 0.4);;
}
.Nav_links li {
    padding-left: 30%;
    list-style: none;
}
.Nav_links a{
    text-decoration: none;
    font-size: large;
    font-weight: 500;
    color: white;
}

.Nav_links a:hover{
    color:rgb(0, 141, 141);
    text-decoration: underline;
}

.Home_main{
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 10%;
    justify-content: center;
}
.Home_text{
    margin: auto;
    
}
.Home_text p {
    color: white;
    margin: 0%;
    font-size:x-large
}
.Home_p2{
    padding-top: 10px;
    overflow: hidden; 
    white-space: nowrap;
    animation: typing 4s steps(40) forwards;
}
@keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
}
.Home_A{
    font-weight: 50;
    font-size: 18px
    
}
.Home_text h1{
    color: white;
    margin: 0%;
    font-size: 3em;
}
.aPortfolio{
    margin-top: 20px;
    background-color: rgb(0, 141, 141);
    width: fit-content;
    border-radius: 20px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-top: 4px;
    cursor: pointer;
}
.aPortfolio:hover{
    opacity: 0.7;
}
.aPortfolio a{
   
    text-decoration: none;
    color: white;
    font-weight: 600;
}
.linksHome{
    display: flex;
    gap: 20px;
    margin-top: 20px;
}
.linksdivs{
    border-radius: 50px;
    border: solid white 2px;
    width: 35px;
    padding-top: 4px;
    padding-bottom: 6px;
    text-align: center;
    cursor: pointer;
}
.linksdivs a{
    text-decoration: none;
    color:rgb(0, 141, 141);
    margin: 0%;
    font-weight: 700;
}
.linksdivs:hover {
     a{
        color: white;
    }
    background-color: rgb(0, 141, 141);
   
}


.colorword{
    color: rgb(0, 141, 141);
    padding-right: 30px;
}
.Home_foto img{
    border-radius: 30px;
    box-shadow: 3px 3px 3px rgb(0, 141, 141);
}

@media (max-width: 768px) {
    .Home_main{
        
        grid-template-columns: auto;
        
    }
    .Home_text{
        display: grid;
        margin-left: 10%;
        margin-top: 30px;
        margin-bottom: 20px;
        order: 2;
        
    }
    .Home_foto{
        display: grid;
        justify-content: center;
      
    }
    

}

.About_main{
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 7%;
    justify-content: center;
}
.About_foto{
    display: grid;
    margin-right: 30px;
    justify-content: center;
 
}


.A_symbol{
    color: white;
    font-size: xx-large;
    margin-top: 190px;
}
.A_foto1 img{
    border-radius: 10px;
    box-shadow: 3px 3px 3px  rgb(0, 141, 141) ;
    margin-bottom: 10px;
}
.A_foto2 img{
    border-radius: 10px;
    box-shadow: 3px 3px 3px  rgb(0, 141, 141) ;
}
.About_text{
    margin-left: 30px;
    width: 40vw;
   
}
.About_text h3{
    color:rgb(0, 141, 141) ;
    overflow: hidden; 
    white-space: nowrap;
    animation: typing 4s steps(40) forwards;
}
.About_text p{
    color: white;
    text-align: justify;
}
.skills{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}
.skills img{
    border-radius: 10px;
    box-shadow: 3px 3px 3px rgb(0, 141, 141) ;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
   
    .About_main{
        grid-template-columns: auto;
    }
    .About_foto{
       margin-right: 0px;
    }
    .About_text{
        margin-left: 20px;
        width: auto;
        margin-right: 20px;
    }
    .skills{
        grid-template-columns: auto auto auto;
        justify-content: center;
        gap: 30px;
    }
   
}
@media (max-width: 280px) {
    .About_foto img{
        margin-right: 0px;
        width: 240px;
     }
     .A_symbol{
      
        margin-top: 130px;
    }
    
}


.contact{
    display: grid;
    margin-top: 3%;
    margin-left: 5%;
}
.con_text{
    color: white;
    font-size: xx-large;
    font-weight: 600;
    margin-bottom: 20px;
 
  
}
.con_text p{
    margin:0%
}
.con_daten{
    display: flex;
   
   
}
.con_dd{
    color: white;
    font-size: large;
    font-weight: 500;
    border-bottom: 2px solid white;
    padding-right: 5vw;
    margin-right: 2vw;
}
.con_p1{
    color:rgb(0, 141, 141) ;
}
.textSend{
    display: grid;
    gap: 20px;
    width: 74%;
    margin-bottom: 20px;
}
.textSend h1{
    color: rgb(0, 141, 141)
}
.textSend input{
    font-size: large;
    padding: 8px;
    border-radius: 5px;
}
.textSend textarea{
    height: 90px;
    font-size: large;
    border-radius: 5px;
}
.textSend button{
    font-size: large;
    font-weight: 600;
    border-radius: 5px;
    padding: 5px;
    background-color: rgb(0, 141, 141);
    color: white;
    cursor: pointer;

}
.textSend button:hover{
    opacity: 0.6;
}

@media (max-width: 768px) {
    .con_daten{
        display: grid;
        grid-template-columns: auto;
    }
    .contact{
      margin-left: 10px;
    }
    .textSend{
        display: grid;
        gap: 20px;
        width: 97%;
        margin-bottom: 20px;
    }
}
@media (max-width: 1024px) {
    .textSend{
        display: grid;
        gap: 20px;
        width: 98%;
        margin-bottom: 20px;
    }
}
.centerPortfolio{
    margin: 20px;
}
.titlePortfolio{
    color:white;
    text-align: center;
    position: relative;
    animation: floatAnimation 3s ;
  
   
}

@keyframes floatAnimation {
    0%, 100% {
      transform: translateY(0); /* Anfangs- und Endposition */
    }
    50% {
      transform: translateY(-10px); /* Maximaler Anstieg */
    }
  }

.portfolio{
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
   
    padding-bottom: 20px;
    
}
.skillsImg{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.Project{
    position: relative;
    opacity: 0.7;
    background-size: cover;
    background-position: center;
    width: 350px;
    height: 330px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 3px 3px rgb(0, 141, 141);
    
}
.ProjektText p{
    border-bottom: 2px solid white;
}
.projectName{
    color: white;
 }

.Details{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    transition: .5s ease;
    text-align: justify;
 
}


.Details h3{
    text-align: center;
    margin-bottom: 0%;
    margin-top: 5px;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    font-size:large;
}

.Details p{
    padding: 0%;
    margin: 8px;
    color: white;
    
}
.DetailsDatenLinks{
    display: flex;
    justify-content: center;
}
.DetailsDaten{
    display: flex;
}
.DetailsDaten ul{
    margin: 0%;
    padding: 4px;
}
.DetailsDaten li{
    text-decoration: none;
    color: white;
    font-size: small;
    margin: 0%;
    padding: 0%;
    list-style-type: none;
}
.DetailsLinks{
    display: flex;
    margin-top: 5px;
    justify-content: center;
    gap: 10px;
}
.DetailsLinks img{
    transition: transform 0.3s ease-in-out;
}
.DetailsLinks img:hover{
    transform: scale(1.3);
}
.Project:hover .Details{
    transform: translateY(0);
    margin-top: 10px; 
}

.liste li{
    font-size: large;
    
}

.NameProject{
    margin: 20px;
    font-size:xx-large;

}







    

    
    
